<template>
  <el-dialog :title="$t('homeAlarm.name')" custom-class="v-dialog v-dialog--lg v-dialog--mdpadding"
    :visible.sync="visible" @closed="visibleReal=false">
    <el-row class="alarm-search">
      <span>{{$t('homeAlarm.alarmType')}}：</span>
      <el-select v-model="alarmType" @change="alarmTypeChange">
        <el-option v-for="(item, index) in options" :key="index" :label="item.name" :value="item.value"></el-option>
      </el-select>
      <el-select v-model="dayType" v-if="dayVisible">
        <el-option v-for="(item, index) in dayOptions" :key="index" :label="item.name" :value="item.value"></el-option>
      </el-select>
      <el-button type="primary" class="border iml-0" style="width:80px;" @click="fetchDataClick" :loading="tableLoading"
        plain>{{$t('homeAlarm.query')}}</el-button>
      <el-button type="primary" class="border iml-1" style="width:80px;" @click="exportData" :loading="exportLoading"
        plain>{{$t('homeAlarm.export')}}</el-button>
    </el-row>
    <el-table :data="pageData" height="630px" style="width:100%;" class="imt-2" border stripe v-loading="tableLoading">
      <el-table-column type="index" width="50"></el-table-column>
      <el-table-column :label="$t('homeAlarm.vehicleName')" prop="VehicleName" width="150px"></el-table-column>
      <el-table-column :label="$t('homeAlarm.deviceID')" prop="SIM2" width="150px"></el-table-column>
      <el-table-column :label="$t('homeAlarm.alarmType')" prop="CAlarmType" width="120px"></el-table-column>
      <el-table-column :label="$t('homeAlarm.alarmTime')" prop="GPSTime" width="160px">
        <div slot-scope="{row}">
          <span>{{row.GPSTime ? miment(row.GPSTime).format() : ''}}</span>
        </div>
      </el-table-column>
      <el-table-column :label="$t('homeAlarm.alarmPlace')">
        <div slot-scope="{row}">
          {{row.Address}}
          <!-- <span-address :lon="row.Lon" :lat="row.Lat" show-default></span-address> -->
        </div>
      </el-table-column>
    </el-table>
    <el-pagination slot="footer" class="v-pager" background :page-size="pageSize" @size-change="handleSizeChange"
      @current-change="handleCurrentchange" :current-page="pageIndex" layout="total, sizes, prev, pager, next, jumper"
      :total="list.length" popper-class="custom">
    </el-pagination>
  </el-dialog>
</template>

<script>
  import {
    GetAlarmDetail_af as GetAlarmDetail,
    ExportAlarmDetail_af as ExportAlarmDetail
  } from '@/api/home'
  import {
    GetAddressList
  } from '@/api/common'
  import {
    saveBlob
  } from '@/common/utils'
  import miment from 'miment'
  export default {
    data() {
      return {
        miment,
        visible: false,
        visibleReal: false,
        list: [],
        pageData: [],
        tableLoading: false,
        exportLoading: false,
        alarmType: null,
        pageSize: 20,
        pageIndex: 1,
        dayType: 2,
        dayVisible: false,
        dayOptions: [{
            name: '全部',
            value: 1
          },
          {
            name: '3天内',
            value: 2
          },
          {
            name: '7天内',
            value: 3
          }
        ]
        // options: [
        //   {name:'拆机报警',value:'拆机报警'},
        //   {name:'剪线报警',value:'剪线报警'},
        //   {name:'区域报警',value:'区域报警'},
        //   {name:'低电压报警',value:'低电压报警'}
        // ]
      }
    },
    created() {

    },
    computed: {
      options() {
        // alarmType1 拆机报警
        // alarmType2 剪线报警
        // alarmType3 区域报警
        // alarmType4 低电压报警
        return [

          {
            name: this.$t('homeAlarm.alarmType5'),
            value: '双离线报警',
            range: false
          },
          {
            name: '单离线报警',
            value: '单离线报警',
            range: false
          },
          {
            name: this.$t('homeAlarm.alarmType2'),
            value: '拆机报警',
            range: true
          },
          {
            name: this.$t('homeAlarm.alarmType3'),
            value: '剪线报警',
            range: true
          },
          {
            name: this.$t('homeAlarm.alarmType6'),
            value: '设备分离报警',
            range: false
          },
          {
            name: this.$t('homeAlarm.alarmType7'),
            value: '出省报警',
            range: true
          },
          {
            name: this.$t('homeAlarm.alarmType8'),
            value: '二押点报警',
            range: true
          },
          {
            name: '停驶报警',
            value: '停驶报警',
            range: false
          },
          {
            name: this.$t('homeAlarm.alarmType1'),
            value: '低电压报警',
            range: true
          },
          {
            name: '低电量报警',
            value: '低电量报警',
            range: false
          }
        ]
      },
      // pageData() {
      //   return this.list.slice(this.pageIndex*this.pageSize - this.pageSize, this.pageIndex*this.pageSize)
      // }
    },
    methods: {
      alarmTypeChange(type) {
        this.exportLoading = false
        this.tableLoading = false
        const index = this.options.findIndex(k => k.name == type)
        if (index != -1) {
          this.dayVisible = this.options[index].range
        }
      },
      open(type) {
        if (type) {
          if (type == '设备分离') {
            type = '设备分离报警'
          }
          this.alarmType = type
          const index = this.options.findIndex(k => k.name == type)
          if (index != -1) {
            this.dayVisible = this.options[index].range
          }
        }
        this.pageSize = 10
        this.pageIndex = 1
        this.fetchData().then(res => {
          this.fetchAddress(res)
        })
        this.visible = true
        this.visibleReal = true
      },
      handleCurrentchange(val) {
        this.pageIndex = val
        let pageData = this.list.slice(this.pageIndex * this.pageSize - this.pageSize, this.pageIndex * this.pageSize)
        this.fetchAddress(pageData)
      },
      handleSizeChange(val) {
        this.pageSize = val
        let pageData = this.list.slice(this.pageIndex * this.pageSize - this.pageSize, this.pageIndex * this.pageSize)
        this.fetchAddress(pageData)
      },
      fetchData() {
        const promise = new Promise((resolve) => {
          this.tableLoading = true
          GetAlarmDetail(this.alarmType, this.dayType).then(ret => {
            this.tableLoading = false
            if (ret.data.errCode) {
              return reject(ret.data)
            }
            this.list = []
            if (ret.data && ret.data.length === 0) {
              this.$message({
                type: 'info',
                message: this.$t('homeAlarm.tip2')
              })
            }
            let list = ret.data
            if (list) {
              list.forEach(element => {
                this.options.forEach(item => {
                  if (element.CAlarmType === item.value) {
                    element.CAlarmType = item.name
                  }
                })
              });
            }
            this.list = list
            let pageData = this.list.slice(this.pageIndex * this.pageSize - this.pageSize, this.pageIndex * this
              .pageSize)
            resolve(pageData)
          })
        })
        return promise
      },
      exportData() {
        this.exportLoading = true
        ExportAlarmDetail(this.alarmType, this.dayType).then(ret => {
          this.exportLoading = false
          saveBlob(ret.data, `${this.$t('homeAlarm.name')}.xlsx`)
        }).catch(err => {
          this.exportLoading = false
        }).finally(() => {
          this.exportLoading = false
        })
      },
      fetchDataClick() {
        this.pageSize = 10
        this.pageIndex = 1
        this.fetchData().then(res => {
          this.fetchAddress(res)
        })
      },
      fetchAddress(pages) {
        let temp = [];
        pages.forEach(k => {
          let item = {
            'Lon': k.Lon,
            'Lat': k.Lat,
            'ObjectID': k.VehicleID
          }
          temp.push(item)
        })
        const promise = new Promise((resolve) => {
          GetAddressList(temp).then((ret) => {
            if (ret.data.errCode) {
              return reject(ret.data)
            }
            pages.forEach(k => {
              ret.data.forEach(j => {
                if (j.ObjectId === k.VehicleID) {
                  k.Address = j.Address
                }
              })
            })
            this.pageData = pages
            resolve(this.pageData)
          })

        })
        return promise
      },
      close() {

      }
    }
  }

</script>

<style lang="scss" scoped>
  .el-row {
    margin-bottom: 8px;

    .el-select {
      margin-right: 8px;
    }
  }

  .el-pagination {
    margin-top: 8px;
  }

  .alarm-search {
    &>span {
      font-size: 13px;
      float: left;
      height: 32px;
      line-height: 32px;
      padding: 0 12px 0 0;
    }

    &>.el-select,
    &>.el-button {
      float: left;
    }
  }

</style>
